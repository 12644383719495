export default [
  {
    path: "/",
    text: "Home",
  },
  {
    path: "/places",
    text: "Rehabs",
  },
  {
    path: "/blog",
    text: "News",
  },
  {
    path: "/donation",
    text: "DONATE",
  },
  {
    path: "/portal",
    text: "Portal",
  },
  // {
  //   path: "/places",
  //   text: "Donate",
  // }
]
