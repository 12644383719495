import React from "react"
import {
  FaFacebook,
  FaTwitterSquare,
  FaInstagram,
  FaStopCircle,
  FaHandsHelping,
  FaHeartbeat,
  FaGifts, FaTwitter, FaDonate, FaRegHeart, FaHandHoldingHeart
} from "react-icons/fa"

export default [
  {
    icon: <FaHandsHelping/>,
    url: "/contact",
  },
  {
    icon: <FaHandHoldingHeart />,
    url: "/donation",
  },
  {
    icon: <FaDonate />,
    url: "/donation",
  },
]
