import React from "react"
import styles from "../css/footer.module.css"
import links from "../constants/links"
import socialIcons from "../constants/social-icons"
//import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {graphql} from "gatsby";

import power from '../images/Poweredby.gif'
import * as https from "https";

const Footer = () => {
    return (
    <footer className={styles.footer}>
        <div className={styles.columnleft}>
            <div>
                <a href="https://terrahealthsolutions.com" target="_blank">
                    <img src={power} alt="Powered by Banjo" height="150px" />
                </a>
            </div>
        </div>

            <div className={styles.links}>
            {links.map((item, index) => {
                return (
                <AniLink paintDrip hex="#AEECEE" key={index} to={item.path}>
                    {item.text}
                </AniLink>
                )
            })}
            </div>
            <div className={styles.icons}>
            {socialIcons.map((item, index) => {
                return (
                <a key={index} href={item.url} target="_blank" rel="noopener noreferrer">{item.icon}</a>
                )
            })}
            </div>
            <div className={styles.copyright}>
                copyright &copy; GetTheCare.org {new Date().getFullYear()} all rights reserved
            </div>

    </footer>
)
}

export default Footer;
